import { init } from '@elastic/apm-rum';

const apm = init({
  active: true,
  serverUrl: '/api/apm',
  serverUrlPrefix: '/whisky/',
  serviceName: 'portal-interim-home',
  serviceVersion: window.env?.version ?? 'local',
  environment: window.env?.stage ?? 'dev',
});

const portalStartTimestamp = new Date();
apm.setCustomContext({ portalStartTimestamp });

window.debugModuleFederation = () => {
  console.log(__webpack_share_scopes__);
};

import('./src/Application.container');
